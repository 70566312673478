<style lang="scss">
  @import '~@assets/scss/home';
  @import '~@assets/scss/theme-da';

  #home-app {

  }
</style>

<template>
  <div id="home-app">
    <svg xmlns="http://www.w3.org/2000/svg" style="position: absolute; width: 0; height: 0" aria-hidden="true">
      <defs>
        <linearGradient id="theme_svg_gradient" x1="70.69%" x2="21.188%" y1="13.678%" y2="100%">
          <stop offset="0%" stop-color="#F59C07"/>
          <stop offset="100%" stop-color="#F57507"/>
        </linearGradient>
      </defs>
    </svg>
    <router-view/>
    <cebc-message/>
  </div>
</template>
<script>
import CebcMessage from '@components/Dashboard/NewDashboard/CebcMessage'

export default {
  components: { CebcMessage },
}
</script>
