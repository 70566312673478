<style scoped lang="scss">
  .main-channels {
    padding-top: 45px;
    padding-bottom: 60px;
    background-color: $additional-3;
    color: $bright;

    .block-description {
      max-width: 900px;
      margin-top: 20px;
      @include margin-x(auto);
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: -0.2px;
      color: #838383;
      text-align: center;
      @include padding-x(20px);
    }

    @include media(max, lg) {
      @include padding-x(15px);
    }

    @include media(max, sm) {
      @include padding-y(30px);
    }

    .channels-container {
      @include margin-x(auto);

      @include media(min, lg) {
        width: 1150px;
      }

      .channel-tabs {
        display: flex;
        justify-content: center;

        .channel-tab {
          line-height: 1.15;
          font-size: 26px;
          position: relative;
          cursor: pointer;

          @include media(max, sm) {
            font-size: 24px;
          }

          //&.active:after {
          //  content: '';
          //  display: block;
          //  position: absolute;
          //  width: 100%;
          //  left: 0;
          //  top: 100%;
          //  margin-top: 5px;
          //  height: 2px;
          //  background-image: linear-gradient(269deg, var(--theme-color-main), var(--theme-color-additional));
          //}
        }

        .channel-tab + .channel-tab {
          margin-left: 40px;

          @include media(max, sm) {
            margin-left: 18px;
          }
        }
      }

      .channels-content {
        margin-top: 45px;
        display: grid;
        grid-column-gap: 25px;
        grid-row-gap: 25px;
        grid-template-rows: 411px 45px;
        grid-template-columns: 730px 385px;

        @include media(max, lg) {
          grid-template-columns: 530px 385px;
        }

        @include media(max, md) {
          display: block;
        }

        @include media(max, sm) {
          margin-top: 25px;
        }
      }

      .channels-list {
        display: grid;
        grid-template-rows: repeat(4, 84px);
        grid-row-gap: 25px;

        .channels-list-item {
          display: flex;
          position: relative;
          padding: 12px;
          background-color: $additional-2;
          border-radius: 3px;
          cursor: pointer;

          &.active {
            background-image: linear-gradient(255deg, var(--theme-color-main), var(--theme-color-additional));
          }

          &:hover {
            background-color: $additional-1;
          }

          .channel-avatar {
            img {
              width: 60px;
              height: 60px;
              border-radius: 100%;
            }
          }

          .channel-info {
            margin-left: 12px;

            .channel-name {
              font-size: 15px;
              font-weight: 600;
            }

            .channel-category {
              font-size: 13px;
              font-weight: 500;
              width: 170px;
              color: $warm-grey;
            }
          }

          &.active {
            .channel-info .channel-category {
              color: $bright;
            }
          }

          .channel-viewers {
            position: absolute;
            right: 12px;
            bottom: 12px;
            font-size: 13px;
            font-weight: 600;
            line-height: normal;
            letter-spacing: normal;

            .icon {
              margin-right: 5px;
              margin-top: -2px;
            }
          }
        }
      }

      .channel-preview-info {
        .info-streamer {
          display: flex;
          align-items: center;

          .streamer-avatar {
            img {
              width: 60px;
              height: 60px;
              border-radius: 100%;
            }
          }

          .streamer-name {
            margin-left: 15px;
            font-size: 22px;
            font-weight: 500;
          }

          .streamer-verified-icon {
            font-size: 22px;
            margin-left: 10px;
          }
        }

        .info-tags {
          margin-top: 15px;
          display: flex;

          .info-tag {
            background-color: $additional-2;
            padding: 3px 12px;
            border-radius: 14px;
            font-size: 13px;
            font-weight: 600;

            &.viewers .icon {
              margin-top: -3px;
              margin-right: 5px;
            }
          }

          .info-tag + .info-tag {
            margin-left: 10px;
          }
        }

        .info-description {
          position: relative;
          margin-top: 15px;
          color: $warm-grey;
          letter-spacing: -0.2px;
          font-size: 15px;
          line-height: 1.6;
          height: 295px;
          overflow: hidden;

          &:after {
            content: '';
            display: block;
            width: 100%;
            position: absolute;
            bottom: 0;
            height: 50px;
            pointer-events: none;
            background-image: linear-gradient(to bottom, rgba($additional-3, 0), rgba($additional-3, 1));
          }
        }
      }

      .channels-info {
        @include media(max, md) {
          display: none;
        }
      }

      .channel-preview-stream {
        background-size: cover;
        position: relative;

        @include media(max, md) {
          height: 400px;
        }

        @include media(max, sm) {
          height: 163px;
        }

        iframe {
          width: 100%;
          height: 100%;
        }

        .channel-link {
          @include position-all(absolute, 0);
        }
      }

      .channel-preview-title {
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        font-size: 20px;
        letter-spacing: normal;
        white-space: nowrap;

        @include media(max, md) {
          display: none;
        }

        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          top: 0;
          right: 0;
          width: 100px;
          pointer-events: none;
          background-image: linear-gradient(to right, rgba($additional-3, 0), rgba($additional-3, 1));
        }
      }

      .channels-controls {
        display: grid;
        grid-template-columns: 45px 1fr 45px;
        grid-column-gap: 10px;

        @include media(max, md) {
          margin-top: 15px;

          grid-template-columns: 1fr 1fr;
        }

        .control-button {
          &.all {
            font-weight: 600;
            letter-spacing: normal;
            text-transform: uppercase;
          }
        }
      }
    }
  }
</style>

<template>
  <div class="main-channels">
    <div class="channels-container">
      <div class="channel-tabs">
        <div
          class="channel-tab"
          :class="{active: currentTab === 'ambassadors'}"
          @click="setActiveTab('ambassadors')">{{ $t('home.mainChannels.streamersCount') }}
        </div>
        <!--        <div-->
        <!--          v-if="streamers.length"-->
        <!--          class="channel-tab"-->
        <!--          :class="{active: currentTab === 'streamers'}"-->
        <!--          @click="setActiveTab('streamers')">{{ $t('home.mainChannels.featured') }}-->
        <!--        </div>-->
      </div>
      <div class="block-description">
        {{ $t('home.mainChannels.description') }}
      </div>
      <div class="channels-content">
        <div v-if="currentChannel" class="channel-preview-stream" ref="preview" :style="previewStyles">
          <a v-if="$appIsSM" :href="currentChannel.link" class="channel-link"/>
          <iframe
            v-else
            :key="iframeKey"
            :src="currentChannel.url"
            autoplay="false"
            id="player_embed"
            frameborder="0"
            scrolling="no"
            allowfullscreen="true"/>
        </div>
        <div class="channels-info">
          <div v-if="currentTab === 'streamers'" class="channels-list">
            <div
              v-for="(streamer, index) in streamers"
              :key="streamer.id"
              class="channels-list-item"
              :class="{active: index === currentStreamerIndex}"
              @click="selectStreamer(index)">
              <div class="channel-avatar">
                <img :src="streamer.avatar"/>
              </div>
              <div class="channel-info">
                <div class="channel-name">{{ streamer.name }}</div>
                <div class="channel-category">{{ streamer.game }}</div>
              </div>
              <div class="channel-viewers">
                <icon name="user"/>
                {{ streamer.viewers }}
              </div>
            </div>
          </div>
          <div v-else-if="currentAmbassador" class="channel-preview-info">
            <div class="info-streamer">
              <div class="streamer-avatar">
                <img :src="currentAmbassador.avatar"/>
              </div>
              <div class="streamer-name">{{ currentAmbassador.name }}</div>
              <div v-if="currentAmbassador.isVerified" class="streamer-verified-icon">
                <icon name="verified"/>
              </div>
            </div>
            <div v-if="currentAmbassador.viewers || currentAmbassador.game" class="info-tags">
              <div v-if="currentAmbassador.viewers" class="info-tag viewers">
                <icon name="user"/>
                {{ currentAmbassador.viewers }}
              </div>
              <div v-if="currentAmbassador.game" class="info-tag game">
                {{ currentAmbassador.game }}
              </div>
            </div>
            <div class="info-description">{{ currentAmbassador.description }}</div>
          </div>
        </div>
        <div>
          <div class="channels-controls">
            <btn class="control-button prev control" @click="prevStreamer">
              <icon name="chevron-left"/>
            </btn>
            <!--          <btn href="showcase" class="control-button all">{{ $t('home.mainChannels.all') }}</btn>-->
            <div class="channel-preview-title" v-if="currentChannel">{{
                currentChannel.title
              }}
            </div>
            <btn class="control-button next control" @click="nextStreamer">
              <icon name="chevron-right"/>
            </btn>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MainChannels',
  data() {
    return {
      currentTab: 'ambassadors',

      currentAmbassadorIndex: 0,
      currentStreamerIndex: 0,

      streamers: [],
      ambassadors: [],
    }
  },
  computed: {
    currentStreamer() {
      return this.streamers[this.currentStreamerIndex]
    },

    currentAmbassador() {
      return this.ambassadors[this.currentAmbassadorIndex]
    },

    currentChannel() {
      return this.currentTab === 'ambassadors' ? this.currentAmbassador : this.currentStreamer
    },

    iframeKey() {
      return [this.currentTab, this.currentAmbassadorIndex, this.currentStreamerIndex].join('_')
    },

    previewStyles() {
      const _appWidth = this.$appWidth

      const styles = {
        backgroundImage: this.currentChannel.previewBig ? `url(${this.currentChannel.previewBig})` : '',
      }

      if (this.$appIsSM && this.$refs.preview) {
        const { width: previewWidth } = this.$refs.preview.getBoundingClientRect()
        const previewHeight = Math.round(previewWidth / (16 / 9))

        styles.height = `${previewHeight}px`
      }

      return styles
    },
  },
  created() {
    this.streamers = window._streamers.map(streamer => ({
      game: streamer.platform.game,
      previewSmall: streamer.platform.preview_medium,
      previewBig: streamer.platform.preview_large,
      title: streamer.platform.title,
      url: streamer.platform.url,
      viewers: streamer.platform.viewers,
      avatar: streamer.user.avatar,
      name: streamer.user.name,
      link: `https://twitch.tv/${streamer.user.name}`,
    }))

    this.ambassadors = _.sortBy(window._ambassadors.map(streamer => {
      let ambassador = {
        online: false,
        sort: 1000 + _.random(1, 100),
        avatar: streamer.avatar,
        name: streamer.name,
        link: `https://twitch.tv/${streamer.name}`,
        description: streamer.channel_description,
        isVerified: streamer.is_verified,
        url: streamer.twitch_iframe_url,
      }

      if (streamer.active_stream && streamer.active_stream.platform) {
        const platform = streamer.active_stream.platform

        ambassador = {
          ...ambassador,
          online: true,
          sort: _.random(1, 100),
          game: platform.game,
          previewSmall: platform.preview_medium,
          previewBig: platform.preview_large,
          title: platform.title,
          viewers: platform.viewers,
        }
      }

      return ambassador
    }), 'sort')
  },
  methods: {
    setActiveTab(tab) {
      this.currentTab = tab
    },

    selectStreamer(index) {
      this.currentStreamerIndex = index
    },

    prevStreamer() {
      if (this.currentTab === 'ambassadors') {
        this.currentAmbassadorIndex = this.currentAmbassadorIndex <= 0 ? this.ambassadors.length - 1 : this.currentAmbassadorIndex - 1
      } else {
        this.currentStreamerIndex = this.currentStreamerIndex <= 0 ? this.streamers.length - 1 : this.currentStreamerIndex - 1
      }
    },

    nextStreamer() {
      if (this.currentTab === 'ambassadors') {
        this.currentAmbassadorIndex = this.currentAmbassadorIndex >= this.ambassadors.length - 1 ? 0 : this.currentAmbassadorIndex + 1
      } else {
        this.currentStreamerIndex = this.currentStreamerIndex >= this.streamers.length - 1 ? 0 : this.currentStreamerIndex + 1
      }
    },
  },
}
</script>
