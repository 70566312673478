<style scoped lang="scss">
  .age-check-modal {
    .age-check-step-1 {
      max-width: 420px;
      text-align: center;
      padding-top: 30px;
      margin: auto;

      .age-check-text {
        margin-top: 20px;
        font-size: 24px;
      }
    }

    .age-check-step-2 {
      max-width: 540px;
    }

    .age-modal-section + .age-modal-section {
      margin-top: 20px;
    }

    .email-text {
      color: var(--theme-color-additional);
    }

    .age-centered-controls {
      display: flex;
      align-items: center;
      justify-content: center;

      .button + .button {
        margin-left: 20px;
      }
    }
  }
</style>

<template>
  <modal class="age-check-modal" v-model="modalOpened" forced centered>
    <template v-if="modalTitle" slot="title">{{ modalTitle }}</template>
    <template slot="body-content">
      <div v-show="step === 1" class="age-check-step-1">
        <div class="age-check-image">
          <img :src="ageCheckImage"/>
        </div>
        <div class="age-check-text">Are you over 13 years old?</div>
      </div>
      <div v-show="step === 2" class="age-check-step-2">
        <div class="age-modal-section">
          Children under the age of 13 have to get parental consent to use Donation Alerts.
          Please enter your parent/guardian valid email address to keep him informed
          and in control of their child's information to grant the official approval to your account registration.
        </div>
        <div class="age-modal-section">
          <form-group inline label="Parent/guardian email">
            <input-addon placeholder="Enter e-mail" v-model="email"/>
          </form-group>
        </div>
        <div class="age-modal-section">
          <info-box v-if="error" variant="error">{{ error }}</info-box>
        </div>
      </div>
      <div v-show="step === 3" class="age-check-step-3">
        <div class="age-modal-section">The email request was sent successfully to:
          <span class="email-text">{{ email }}</span>
        </div>
        <div class="age-modal-section">
          After getting an email request your parent/guardian needs to click the following link to confirm an agreement to your registration.
          <br>Once that happens, you can create a DonationAlerts account.
        </div>
        <div class="age-modal-section">
          <info-box v-if="message">{{ message }}</info-box>
          <info-box v-if="error" variant="error">{{ error }}</info-box>
        </div>
      </div>
    </template>
    <template v-if="step === 1">
      <div slot="footer-content" class="age-centered-controls">
        <btn variant="primary-action" @click="confirmUserIsAdult">Yes</btn>
        <btn variant="secondary-action" @click="confirmUserIsChild">No</btn>
      </div>
    </template>
    <template slot="controls" v-if="step === 2">
      <btn
        :disabled="sendEmailButtonDisabled"
        :is-loading="loadingState.email"
        variant="primary-action"
        @click="sendEmail">Send
      </btn>
      <btn
        variant="secondary-action"
        @click="backToAgeCheck">Cancel
      </btn>
    </template>
    <template slot="controls" v-if="step === 3 && !success">
      <btn
        :is-loading="loadingState.confirmation"
        variant="primary-action"
        @click="checkConfirmation">Check consent
      </btn>
      <btn
        :is-loading="loadingState.email"
        variant="secondary-action"
        @click="resendEmail">Resend
      </btn>
      <btn
        variant="secondary-action"
        @click="changeEmail">Change e-mail
      </btn>
    </template>
  </modal>
</template>

<script>

import { validateEmail } from '@utils/utils'

const getCookieField = (cookie, field) => {
  const item = cookies.getItem(cookie)

  if (!item) {
    return undefined
  }

  const decodedItem = JSON.parse(cookies.getItem(cookie))

  return decodedItem[field]
}

const setCookieField = (cookie, field, value) => {
  const item = cookies.getItem(cookie)

  if (!item) {
    return undefined
  }

  const decodedItem = JSON.parse(cookies.getItem(cookie))
  if (value === undefined) {
    delete decodedItem[field]
  } else {
    decodedItem[field] = value
  }

  cookies.setItem(cookie, JSON.stringify(decodedItem))
}

export default {
  name: 'AgeCheckModal',
  data() {
    return {
      loadingState: {
        email: false,
        confirmation: false,
      },
      modalOpened: false,
      step: 1,
      email: null,
      error: null,
      message: null,
      success: false,
    }
  },
  computed: {
    modalTitle() {
      return [
        null,
        'Creating an account',
        'Parent/guardian email verification',
      ][this.step - 1]
    },

    ageCheckImage() {
      return this.$asAppAsset('/images/us-age-check.png')
    },

    sendEmailButtonDisabled() {
      return !this.email || this.email.length < 1
    },
  },
  created() {
    const ageCheckPassedCookie = Number(cookies.getItem('age_check_passed'))
    const ageCheckStepCookie = Number(getCookieField('age_check', 'check_step'))
    const ageCheckEmailCookie = getCookieField('age_check', 'parent_email')

    if (ageCheckPassedCookie !== 1 && ageCheckStepCookie) {
      this.modalOpened = true

      this.step = ageCheckStepCookie
      this.email = ageCheckEmailCookie
    }
  },
  methods: {
    setStep(step) {
      setCookieField('age_check', 'check_step', step)
      this.step = step
    },

    passAgeCheck() {
      cookies.setItem('age_check_passed', 1, new Date(new Date().setFullYear(new Date().getFullYear() + 10)))
      const redirectService = getCookieField('age_check', 'service')
      setCookieField('check_step', undefined)
      setCookieField('service', undefined)
      window.location.href = `/auth/${redirectService}`
    },

    confirmUserIsAdult() {
      this.passAgeCheck()
    },

    confirmUserIsChild() {
      this.setStep(2)
    },

    backToAgeCheck() {
      this.setStep(1)
    },

    sendEmail() {
      const emailIsValid = validateEmail(this.email)

      if (emailIsValid) {
        this.loadingState.email = true
        this.error = null
        this.message = null

        api.post('age-check/parent-confirm', {
          email: this.email,
          visitor_id: getCookieField('age_check', 'visitor_id'),
          token: getCookieField('age_check', 'visitor_token'),
        })
          .then(() => {
            setCookieField('age_check', 'parent_email', this.email)

            this.setStep(3)
          })
          .catch((e) => {
            this.error = e.message
          })
          .finally(() => {
            this.loadingState.email = false
          })
      } else {
        this.error = 'E-mail is not valid'
      }
    },

    resendEmail() {
      this.loadingState.email = true
      this.error = null
      this.message = null

      api.put('/age-check/parent-confirm', {
        visitor_id: getCookieField('age_check', 'visitor_id'),
        token: getCookieField('age_check', 'visitor_token'),
      })
        .then(() => {
          this.message = 'Message was sent successfully'
        })
        .catch((e) => {
          this.error = e.message
        })
        .finally(() => {
          this.loadingState.email = false
        })
    },

    checkConfirmation() {
      this.loadingState.confirmation = true
      this.error = null
      this.message = null

      api.get('/age-check/parent-confirm', {
        params: {
          visitor_id: getCookieField('age_check', 'visitor_id'),
          token: getCookieField('age_check', 'visitor_token'),
        },
      })
        .then(({ data }) => {
          if (data.status === 0) {
            this.message = 'Parent/guardian has not yet confirmed the consent'
          }
          if (data.status === 1) {
            this.message = 'Parent/guardian has confirmed the consent'
            this.success = true

            this.passAgeCheck()
          }
        })
        .catch((e) => {
          this.error = e.message
        })
        .finally(() => {
          this.loadingState.confirmation = false
        })
    },

    changeEmail() {
      this.setStep(2)
    },
  },
}
</script>
