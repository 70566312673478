<style scoped lang="scss">
  .home-page {

  }
</style>

<template>
  <div class="home-page">
    <div class="">
      <main-channels/>
    </div>

    <age-check-modal/>
  </div>
</template>

<script>

import MainChannels from '@components/Home/Channels/MainChannels'
import AgeCheckModal from '@components/Home/Channels/AgeCheckModal'

export default {
  name: 'HomePage',
  components: {
    AgeCheckModal,
    MainChannels,
  },
}
</script>
