<style scoped lang="scss">
  .age-check-confirm-page {
    @include padding-y(50px);
    color: $bright;

    .age-check-confirm {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      @include padding-x(30px);

      .age-check-confirm-title {
        margin-top: 25px;
        font-size: 24px;
        line-height: 30px;
      }

      .age-check-confirm-text {
        max-width: 350px;
        margin-top: 16px;
        font-size: 14px;
        color: #909090;
      }

      .age-check-confirm-controls {
        margin-top: 30px;
      }
    }
  }
</style>

<template>
  <div class="age-check-confirm-page">
    <div class="age-check-confirm">
      <div class="age-check-confirm-image">
        <img :src="ageCheckImage"/>
      </div>
      <div class="age-check-confirm-title">
        {{ ageCheckTitle }}
      </div>
      <div class="age-check-confirm-text">
        {{ ageCheckText }}
      </div>
      <div class="age-check-confirm-controls">
        <btn href="/">{{ ageCheckButtonText }}</btn>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AgeCheckConfirmPage',
  data() {
    return {
      success: window._parentConfirmSuccess,
    }
  },
  computed: {
    ageCheckImage() {
      const images = [
        'us-age-check-fail.png',
        'us-age-check-success.png',
      ]

      return this.$asAppAsset(`/images/${images[Number(this.success)]}`)
    },

    ageCheckTitle() {
      return [
        'Oops! Something went wrong',
        'You have confirmed your consent',
      ][Number(this.success)]
    },

    ageCheckText() {
      return [
        'Oops! Something went wrong - the link was updated or is no longer valid.',
        'Thank you! Your consent has been received. Now the child can complete the DonationAlerts account registration.',
      ][Number(this.success)]
    },

    ageCheckButtonText() {
      return [
        'Back to the main page',
        'Close',
      ][Number(this.success)]
    },
  },
}
</script>
