import Vue from 'vue'
import store from '@state/store'
import router from '@router/home'
import HomeApp from './HomeApp'

import { i18n } from '@src/localization/config'

import './_registerComponents'
import './directives'
import './_setup'

import GAPlugin from './plugins/ga'
import TMRPlugin from './plugins/tmr'

Vue.use(GAPlugin)
Vue.use(TMRPlugin)

import frontConfig from '@services/frontConfig'

frontConfig
  .fetchConfig()
  .then(() => {
    window.hvm = new Vue({
      router,
      store,
      i18n,
      render: h => h(HomeApp),
    }).$mount('#home-app')
  })

